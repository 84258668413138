import axiosConfig from '../axiosConfig';
import { toast } from 'react-toastify';
import logActivity from './logActivity';

const updatePages = (fhid, newPages) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/updatePagesMeta`, newPages)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

const setPagesMeta = (fhid, newPages, cb) => {
  for (let i = 0; i < newPages.length; i++) {
    if (newPages[i].page_title === "Home") {
      delete newPages[i].page_path;
      break
    }
  }
  toast.promise(
    updatePages(fhid, newPages),
    {
      pending: 'Updating pages',
      success: 'Pages updated successfully',
      error: 'Unable to update pages'
    }
  ).then((res) => {
    cb()
    logActivity({
      message: `Updated page meta`,
      fhId: fhid,
      mute: true
    })
  })
}

const getNumberOfPresetsUsedPerCity = (fhid, city, state) => {
  const requestData = {
    city: city,
    state: state
  }

  return new Promise((resolve, reject) => {
    axiosConfig.get(`/api/sites/${fhid}/numberOfPresetsUsedPerCity`, {
      params: requestData
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export { setPagesMeta, getNumberOfPresetsUsedPerCity }
