import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min"
import SidebarPage from "../../components/SidebarPage"
import { createContext, useEffect, useState } from "react"
import getSite from "../getSite"
import listSitePages from "../listSitePages"
import { toast } from "react-toastify"
import Table from "../table/Table"
import PagesHeader from "../../components/sites/PagesHeader"
import SitePagesTitle from "./columns/SitePagesTitle"
import SitePagesName from "./columns/SitePagesName"
import SitePagesPath from "./columns/SitePagesPath"
import SitePagesDescription from "./columns/SitePagesDescription"
import { getNumberOfPresetsUsedPerCity } from "../seoUtility"

const SitePagesContext = createContext()

const SitePagesTable = () => {

  const location = useLocation()
  const [site, setSite] = useState(location.state ?? {})
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [pages, setPages] = useState()
  const [edit, setEdit] = useState(false)
  const [presetInformation, setPresetInformation] = useState()

  const columns = [
    {
      label: "Name",
      Component: SitePagesName,
      className: 'lg:w-2/12'
    },
    {
      label: "Title",
      Component: SitePagesTitle,
      className: 'lg:w-3/12'
    },
    {
      label: "Path",
      Component: SitePagesPath,
      className: 'lg:w-2/12'
    },
    {
      label: "Description | Ai Description Preview",
      Component: SitePagesDescription,
      className: 'lg:w-5/12'
    }
  ]

  useEffect(() => {
    setIsLoading(true)
    if (site.length === 0) {
      updateSite(site)
    } else {
      getSite(id).then((site) => {
        updateSite(site)
      })
    }
  }, [])

  const updateSite = (site) => {
    document.title = `${site.id} ${site.name} Pages | Tukios Website Admin`
    setSite(site)
    refreshPageList(site)
  }

  const refreshPageList = () => {
    setIsLoading(true)
    Promise.all([
      getNumberOfPresetsUsedPerCity(site.id, site.city, site.state),
      listSitePages(site.duda_id)
    ])
      .then(([presetInformation, pages]) => {
        setPresetInformation(presetInformation)
        setPages(pages)
        setIsLoading(false)
      }).catch(() => {
        toast.error('Unable to get pages from Duda')
      })
  }

  const handleChange = (e) => {    
    let value = e.target.value
    let name = e.target.name
    let index = e.target.dataset.index
    let currentPages = [...pages]

    if (name === "seo title") {
      currentPages[index].page_seo.title = value
    }
    else if (name === "description") {
      currentPages[index].page_seo.description = value
    }

    setPages(currentPages)
  }

  return (
    <SidebarPage docTitle={`Site Pages | Tukios Website Admin`} maxWidth={true}>
      <SitePagesContext.Provider
        value={{
          site,
          pages,
          presetInformation,
          setPages,
          isLoading,
          setIsLoading,
          edit,
          setEdit,
          refreshPageList,
          updateSite,
          id,
          handleChange
        }}>
        <div className="mb-4">
          <PagesHeader SitePagesContext={SitePagesContext} />
        </div>
        <Table data={pages} columns={columns} Context={SitePagesContext} />
      </SitePagesContext.Provider>
    </SidebarPage>
  )
}

export default SitePagesTable
