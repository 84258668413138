import classNames from "classnames";
import { useContext } from "react";
import ContentShimmer from "react-content-shimmer";

export default function SitePagesTitle({ data, className, Context }) {

  let { isLoading, edit, pages, handleChange } = useContext(Context)

  return (
    <div className={classNames("flex items-center relative", className)}>
      {!isLoading ?
        <div className="flex flex-col w-full">
          {!edit ?
            <div>{data.page_seo.title}</div>
            :
            <input
              type="text"
              name="seo title"
              data-index={pages.indexOf(data)}
              className="text-sm w-full shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              defaultValue={data.page_seo.title}
              onChange={handleChange}
            />
          }
          {(data.page_seo.title && data.page_seo.title.length) &&
            <div className={classNames("text-xs mt-1", data.page_seo.title.length > 70 ? 'text-red-500' : 'text-gray-400')}>Character Count: {data.page_seo.title.length}</div>
          }
        </div>
        :
        <ContentShimmer style={{ height: '14px', width: '200px', borderRadius: '7px' }} />
      }
    </div>
  )
}