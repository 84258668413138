import {
    ViewListIcon,
    DesktopComputerIcon,
    ClipboardCheckIcon,
    ClipboardListIcon,
    ColorSwatchIcon,
    UserIcon,
    TerminalIcon,
    PhoneIcon,
    SearchIcon,
    HeartIcon,
    ShoppingBagIcon
} from '@heroicons/react/outline'  
import { isAdmin, isSDR } from '../utils/users'

const navItemsGenerator = () => {

  let navItems = []

  if(!isAdmin()) navItems.push({ name: 'My Sites', icon: UserIcon, path: '/sites/me' }) 
  navItems.push({ name: 'All Sites', icon: DesktopComputerIcon, path: '/sites' })
  if(!isSDR()) navItems.push({ name: 'New Website', icon: ClipboardCheckIcon, path: '/intake' })
  navItems.push({ name: 'Launch Schedule', icon: ViewListIcon, path: '/launch-schedule' })
  navItems.push({ name: 'Ready for Designer', icon: ColorSwatchIcon, path: '/sites/unassigned' })
  navItems.push({ name: 'Ready for Floral', icon: ShoppingBagIcon, path: '/sites/floral-unassigned' })
  navItems.push({ name: 'Ready for QA', icon: ClipboardListIcon, path: '/sites/qa' })
  navItems.push({ name: 'SEO', icon: SearchIcon, path: '/sites/seo'})
  navItems.push({ name: 'Ready for Coaching', icon: PhoneIcon, path: '/sites/coaching-sessions' })
  navItems.push({ name: 'Scrapes', icon: TerminalIcon, path: '/scrapes' })
  navItems.push({ name: 'GoDaddy Tools', icon: HeartIcon, path:'/godaddy-tools'})
  
  return navItems
}

export default navItemsGenerator
